import "./App.css";
import restaurant from "./assets/restaurant.jpg";
import desktop_sc from "./assets/desktop-sc.png";
import tablet_mobile_sc from "./assets/tablet-mobile-sc.png";
import desktop_lf from "./assets/desktop-lf.png";
import tablet_lf from "./assets/tablet-lf.png";
import mobile_lf from "./assets/mobile-lf.png";
import style_guide from "./assets/style-guide.png";
import desktop_hf from "./assets/desktop-hf.png";
import tablet_hf from "./assets/tablet-hf.png";
import mobile_hf from "./assets/mobile-hf.png";
import redesign_desktop_sc from "./assets/redesign-desktop-sc.jpg";
import redesign_tablet_sc from "./assets/redesign-tablet-sc.png";
import redesign_mobile_sc from "./assets/redesign-mobile-sc.png";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function App() {
  return (
    <div className="App">
      <Navbar expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#introduction">
            <b>Responsive Redesign Project</b>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#introduction">Introduction</Nav.Link>
              <Nav.Link href="#part1">Part 1</Nav.Link>
              <Nav.Link href="#part2">Part 2</Nav.Link>
              <Nav.Link href="#part3">Part 3</Nav.Link>
              <Nav.Link href="https://cs1300-responsive-redesign.pages.dev/redesigned-page/">
                Redesign
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="parent">
        <section className="section" id="introduction">
          <Container>
            <p className="heading">Introduction</p>
            <p>
              This CSCI 1300 project asks students to practice the workflow of
              redesigning a simple website. The process includes analyzing and
              identifying flaws in an existing interface, creating low-fidelity
              and high-fidelity prototypes for various screen sizes, and
              building a responsive website based on those prototypes. The
              website I chose was the official website of{" "}
              <b>D&A House of Pizza</b>, my favorite local pizza place that I
              used to live next to. I have never visited their website before
              this project, but looking at it now, I think that a lot of
              improvements could be made.
            </p>
            <br />
            <p>
              <i>
                N.B. On 10/19/22 this website was updated by the developer to be
                much more presentable, lacking many of the issues I cited below.
                Fortunately, I was able to find the old version of the site that
                I was originally redesigning through the Internet Archive's
                Wayback Machine, and will post that link. Considering this
                situation, I think it would be very interesting to see how my
                take on the redesign compares to theirs.
              </i>
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={restaurant}
                id="restaurant-img"
                alt="A snapshot of the restaurant's interior."
              ></img>
            </div>
            <p className="alt-text">A snapshot of the restaurant's interior.</p>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="part1">
          <Container>
            <p className="heading">Part 1: Identifying Usability Problems</p>
            <p className="subheading">Picking a Web Page</p>
            <p>
              <a href="https://web.archive.org/web/20210127173448/https://www.dandahouseofpizza.com/index.php">
                Link to the original website
              </a>
            </p>
            <p>
              The specific page that I am choosing to redesign is the home page
              because this is where first impressions are made between the
              company and the consumer. Please refer back to the introduction
              section for information about why I chose this website as my
              redesign candidate.
            </p>
            <Row xs={1} md={2}>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={desktop_sc}
                    id="desktop-sc"
                    alt="A screenshot of the web page on desktop device screens."
                  ></img>
                </div>
                <p className="alt-text">
                  A screenshot of the web page on desktop device screens.
                </p>
              </Col>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={tablet_mobile_sc}
                    id="tablet-mobile-sc"
                    alt="A screenshot of the web page on tablet and mobile device screens."
                  ></img>
                </div>
                <p className="alt-text">
                  A screenshot of the web page on tablet and mobile device
                  screens.
                </p>
              </Col>
            </Row>
          </Container>
          <Container>
            <p className="subheading">Finding Problems</p>
            <p>
              <u>Usability</u> is an important umbrella term for describing user
              experiences that refers to the capability of an interface to be
              understood, learned, used, and attractive to the user. For this
              web page, there are a few factors of usability to keep in mind.
            </p>
            <Row xs={1} md={3}>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Learnability</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>
                          Refers to how easily a (new) user can learn the target
                          interface.
                        </li>
                        <li>
                          The navigation bar is in the middle of the page, which
                          means that it is not part of the user's mental model
                          because people expect these bars to be at the top of a
                          webpage, reducing learnability.
                        </li>
                        <li>
                          Having both "contact" and "directions" information
                          under one tab may cause confusion for users, as they
                          usually expect each tab on a page to consist of one
                          idea on a web page. This decreases learnability.
                        </li>
                        <li>
                          The logo of the business (the chef holding a pizza) is
                          placed in an area of the page and sized in a way that
                          users usually don't expect, diminishing learnability.
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Efficiency</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>
                          Refers to how easily a user can perform tasks on the
                          website.
                        </li>
                        <li>
                          The web page does not have a good layout because there
                          is too much space on the sides and top of the screen.
                          The banner takes a lot of space away from the viewable
                          content on the lower half of the page. This results in
                          the user having to sift through extraneous content
                          vertically and horizontally to get the information
                          they need.
                        </li>
                        <li>
                          The important information about the restaurant (i.e.
                          phone number, address, etc.) is scattered throughout
                          the page, which decreases efficiency for the user as
                          they are searching for these things.
                        </li>
                        <li>
                          The important information about the restaurant (i.e.
                          phone number, address, etc.) is not emphasized or
                          highlighted enough compared to other elements of the
                          page, which decreases efficiency for the user as they
                          are searching for these things.
                        </li>
                        <li>
                          In terms of responsiveness, the web page is very
                          difficult to use on mobile and some tablet devices
                          because the elements of the screen don't adjust to fit
                          the screen size. This means that on smaller screens
                          the web page will appear very small with a lot of
                          extra space on the bottom, especially for phones in
                          portrait mode. This makes it more difficult for users
                          to navigate the site as they have to zoom in and
                          scroll.
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Memorability</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>
                          Refers to how easily a user can re-establish
                          proficiency for the use of the target interface after
                          a period of not using it.
                        </li>
                        <li>
                          The important information about the restaurant (i.e.
                          phone number, address, etc.) is scattered throughout
                          the page, which decreases the chance that a user will
                          remember where they are later on.
                        </li>
                        <li>
                          The important information about the restaurant (i.e.
                          phone number, address, etc.) is not emphasized or
                          highlighted enough compared to other elements of the
                          page, which decreases the chance that a user will
                          remember where they are later on.
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container>
            <p className="subheading">Accessibility</p>
            <p>
              WebAIM WAVE is a strong online tool that can be used to detect
              possible accessibility problems with a website. The tool
              highlighted important issues with the restaurant's site that I
              agree with, discussed below.
            </p>
            <Row xs={1} md={3}>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Language Missing or Invalid</Card.Title>
                    <Card.Text>
                      The language of the document is not identified in the
                      code, meaning that screen readers can't figure out the
                      appropriate language with which to read the website's
                      content to the user.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Alt Text Missing</Card.Title>
                    <Card.Text>
                      Multiple images on the web page are missing alternative
                      text, meaning that screen readers won't be able to
                      describe them to the user.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="card-col">
                <Card>
                  <Card.Body>
                    <Card.Title>Contrasting Colors</Card.Title>
                    <Card.Text>
                      There are areas of the web page that have low contrasting
                      color schemes, meaning that users with reduced vision
                      abilities will have a difficult time reading this text.
                      Moreover, the overall color theme of red and green can be
                      difficult for colorblind users to distinguish. Even for
                      users with adequate vision, these low-contrast issues are
                      still unpleasant to look at.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <p>
              Overall, I believe that the problems detected from the WAVE web
              tool are serious accessibility issues that can affect the wider
              user base in addition to the target user base.
            </p>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="part2">
          <Container>
            <p className="heading">Part 2: Visual Redesign</p>
            <p className="subheading">Low-fidelity Wireframing</p>
            <div className="d-flex justify-content-center">
              <img
                src={desktop_lf}
                id="desktop-lf"
                alt="A low-fi wireframe of the desktop view of the web page."
              ></img>
            </div>
            <p className="alt-text">
              A low-fi wireframe of the desktop view of the web page.
            </p>
            <Row xs={1} md={2}>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={tablet_lf}
                    id="tablet-lf"
                    alt="A low-fi wireframe of the tablet view of the web page."
                  ></img>
                </div>
                <p className="alt-text">
                  A low-fi wireframe of the tablet view of the web page.
                </p>
              </Col>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={mobile_lf}
                    id="mobile-lf"
                    alt="A low-fi wireframe of the mobile view of the web page."
                  ></img>
                </div>
                <p className="alt-text">
                  A low-fi wireframe of the mobile view of the web page.
                </p>
              </Col>
            </Row>
            <p className="subheading">Visual Design Style Guide</p>
            <div className="d-flex justify-content-center">
              <img
                src={style_guide}
                id="style-guide"
                alt="A UI style guide for the visual redesign of the web page."
              ></img>
            </div>
            <p className="alt-text">
              A UI style guide for the visual redesign of the web page.
            </p>
            <p className="subheading">High-fidelity Wireframing</p>
            <div className="d-flex justify-content-center">
              <img
                src={desktop_hf}
                id="desktop-hf"
                alt="A high-fi wireframe of the desktop view of the web page."
              ></img>
            </div>
            <p className="alt-text">
              A high-fi wireframe of the desktop view of the web page.
            </p>
            <Row xs={1} md={2}>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={tablet_hf}
                    id="tablet-hf"
                    alt="A high-fi wireframe of the tablet view of the web page."
                  ></img>
                </div>
                <p className="alt-text">
                  A high-fi wireframe of the tablet view of the web page.
                </p>
              </Col>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={mobile_hf}
                    id="mobile-hf"
                    alt="A high-fi wireframe of the mobile view of the web page."
                  ></img>
                </div>
                <p className="alt-text">
                  A high-fi wireframe of the mobile view of the web page.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="part3">
          <Container>
            <p className="heading">Part 3: Responsive Redesign</p>
            <p>
              Remember in the introduction when I discussed how the D&A House of
              Pizza website was updated on 10/19? We can now compare my redesign
              to the company's official redesign.
            </p>
            <p>
              <a href="https://cs1300-responsive-redesign.pages.dev/redesigned-page/">
                Link to my redesign of the web page
              </a>
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={redesign_desktop_sc}
                id="redesign-desktop-sc"
                alt="A screenshot of the redesigned web page on desktop device screens."
              ></img>
            </div>
            <p className="alt-text">
              A screenshot of the redesigned web page on desktop device screens.
            </p>
            <Row xs={1} md={2}>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={redesign_tablet_sc}
                    id="redesign-tablet-sc"
                    alt="A screenshot of the redesigned web page on tablet device screens."
                  ></img>
                </div>
                <p className="alt-text">
                  A screenshot of the redesigned web page on desktop device
                  screens.
                </p>
              </Col>
              <Col>
                <div className="d-flex justify-content-center">
                  <img
                    src={redesign_mobile_sc}
                    id="redesign-mobile-sc"
                    alt="A screenshot of the redesigned web page on mobile device screens."
                  ></img>
                </div>
                <p className="alt-text">
                  A screenshot of the redesigned web page on mobile device
                  screens.
                </p>
              </Col>
            </Row>
            <p>
              <a href="https://www.dandahouseofpizza.com/">
                Link to their redesign of the website
              </a>
            </p>
            <p>
              Thank you for reviewing my project! I hope you enjoyed reading it!
            </p>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default App;
